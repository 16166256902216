import React from 'react'
import { Scorepad } from './components/Scorepad'

function App() {
  return (
    <div className="App">
      <Scorepad />
    </div>
  )
}

export default App
